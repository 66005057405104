<template>
  <div class="container" v-if="load">
    <cardTitleCom cardTitle="小程序设置" class="bg-Color marT10">
      <template #cardContent>
        <div class="padd15">
          <el-steps :active="active" simple finish-status="success">
            <el-step title="小程序申请及绑定"></el-step>
            <el-step title="扫码授权"></el-step>
            <!--                        <el-step title="小程序能力使用申请"></el-step>-->
            <el-step title="待微信审核通过自动上架"></el-step>
          </el-steps>

          <div v-if="active == 0" class="card1">
            <el-form :model="form" :rules="rules" ref="refForm">
              <el-form-item
                label="小程序AppId"
                prop="wxAppId"
                label-width="220px"
              >
                <el-input v-model="form.wxAppId"></el-input>
              </el-form-item>
              <el-form-item
                label="小程序原始ID"
                prop="wxOriginalId"
                label-width="220px"
              >
                <el-input v-model="form.wxAppOriginalId"></el-input>
              </el-form-item>
              <el-form-item label="小程序密钥（AppSecret）" label-width="220px">
                <el-input v-model="form.wxAppSecret"></el-input>
              </el-form-item>
              <el-form-item
                label="商家联系方式"
                prop="contactPhone"
                label-width="220px"
              >
                <el-input
                  placeholder="请输入手机号"
                  v-model="form.wxAppTel"
                  class="input-with-select"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="小程序标题" label-width="220px">
                <el-input v-model="form.wxAppTitle"></el-input>
              </el-form-item>
              <el-form-item label="小程序标签" label-width="220px">
                <el-input v-model="form.wxAppTag"></el-input>
              </el-form-item>
              <el-form-item required>
                <accessory-upload
                  listType="picture-card"
                  :limit="1"
                  :fileList="fileList"
                  @getFileItems="getFileItems"
                  title="上传小程序已微信认证截图"
                ></accessory-upload>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div v-if="active == 1" class="el-card card2">
          <iframe
            width="100%"
            height="700px"
            scrolling="yes"
            v-if="wxUrl != ''"
            id="myframe"
            :src="wxUrl"
          ></iframe>
        </div>
      </template>
    </cardTitleCom>
    <div v-if="active == 2" class="">
      <div class="el-card padd15">
        <div class="x-f">
          <i class="el-icon-success"></i>
          <span class="marL10"
            >您已授权隼云科技自动为您的微信小程序“{{
              versionInfo.source_miniprogram
            }}”发布新版</span
          >
        </div>
        <div class="marT10">
          <span class="marL20 fontColor fS14MR10"
            >若您要取消授权请您去微信小程序后台取消第三方授权</span
          >
        </div>
      </div>
      <cardTitleCom cardTitle="版本审核" class="bg-Color marT10">
        <template #cardContent>
          <div class="padd15 x-bc fontColor">
            <div class="x-f">
              <div class="y-c" style="color: #333333; width: 120px">
                <div class="fontS16">
                  v<span class="fontS20">{{ versionInfo.user_version }}</span>
                </div>
                <span class="fS14MR10 fontColor marT10">版本号</span>
              </div>
              <div
                class="padd15 y-start COL"
                style="border-left: 1px solid #ababab"
              >
                <div class="x-f">
                  <div>
                    提审者：<span class="fontColor2F">{{
                      versionInfo.developer
                    }}</span>
                  </div>
                  <div class="marL100">
                    发布时间：<span class="fontColor2F">{{
                      setTimestampToTime(versionInfo.create_time || "")
                    }}</span>
                  </div>
                  <div class="x-f marL100">
                    体验二维码:
                    <el-button type="text" @click="openDialogFn"
                      >点击查看</el-button
                    >
                  </div>
                </div>
                <div>
                  <div class="x-f">
                    项目备注:
                    <span class="fontColor2F">{{ versionInfo.user_desc }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span v-if="auditStatus === 0">审核成功</span>
              <span v-if="auditStatus === 1">审核被拒绝</span>
              <span v-if="auditStatus === 2"
                >审核中
                <el-button type="text" @click="getStatue()"
                  >刷新状态</el-button
                ></span
              >
              <span v-if="auditStatus === 3">已撤回</span>
              <span v-if="auditStatus === 4">审核延后</span>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <div class="padd15 x-c fontColor bg-Color">
      <el-button v-if="active >= 1" type="primary" @click="lastStep()"
        >上一步</el-button
      >
      <el-button v-if="active <= 1" type="primary" @click="saveNext()">
        下一步
      </el-button>
      <el-button v-if="active == 2" type="primary" @click="uploadCode(true)">
        提交审核
      </el-button>
    </div>

    <!-- 图片放大对话框 -->
    <el-dialog :visible.sync="openDialog" width="30%" :title="title">
      <img width="100%" :src="QRCodeImg" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import CardTitleCom from "@/views/components/cardTitleCom/index.vue";
import CButton from "@/views/components/c-button/index.vue";
import AccessoryUpload from "@/views/components/accessoryUpload/index.vue";
import {
  authorizationImageUrl,
  wxAppGetDetail,
  wxAppSave,
  wxAppUpdate,
  wxAppGetStep,
  wxAppSetStep,
  tenantWxAppGetDetail,
} from "@/api/O2OMall/config/wxApp";
import { TemplatedRaftList } from "@/api/tenant/config/wxaTemplate";
import { timestampToTime } from "@/utils/index.js";
import {
  queryAuditStatus,
  submitCodeAudit,
  TrialQRCode,
} from "@/api/tenant/config/wxaManage";
import { uploadCode } from "@/api/tenant/config/wxaManage";
import { setPrivacySetting } from "@/api/tenant/config/wxaTemplate";
import { getAuthorizationImageUrl } from "@/api/vip/wxService/authorize"; //微信公众号授权
export default {
  name: "index",
  components: {
    CardTitleCom,
    CButton,
    AccessoryUpload,
  },
  props: {
    wxAppType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      load: false,
      auditStatus: undefined, // 审核状态
      openDialog: false, //图片弹窗开关
      QRCodeImg: undefined, //二维码图片
      title: undefined, //图片弹窗标题
      active: 0,
      form: {
        mallConfigWxAppId: null,
        wxAppTel: "",
        wxAppId: "",
        wxAppSecret: "",
        wxAppAuthImgUrl: "",
        wxAppOriginalId: "",
        wxAppTitle: "",
        wxAppTag: "",
        wxAppType: this.wxAppType,
      },
      rules: {
        wxAppTel: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        wxAppId: [
          {
            required: true,
            message: "请输入微信小程序App ID",
            trigger: "blur",
          },
        ],
        wxAppOriginalId: [
          {
            required: true,
            message: "请输入微信小程序原始ID",
            trigger: "blur",
          },
        ],
        // wxAppAuthImgUrl: [
        //     { required: true, message: '请上传微信小程序已认证截图', trigger: 'blur' },
        // ],
      },
      fileList: [],
      wxUrl: "", // 微信扫码授权页面地址
      versionInfo: {}, // 版本信息
      timer: null,
      isQuery: false, // 是否查询
    };
  },
  created() {
    this.getDetails();
  },
  beforeDestroy() {
    this.clerTimer();
  },
  methods: {
    clerTimer() {
      try {
        clearTimeout(this.timer);
        this.timer = null;
      } catch (error) {}
    },
    async tenantWxAppGetDetail() {
      try {
        const res = await tenantWxAppGetDetail({ wxAppType: this.wxAppType });
        if (res?.data?.tip) {
          this.$message.error(res.data.tip);
          return this.submitNext(false, false);
        }
        if (
          res?.data?.wxAuthorizerCode &&
          res?.data?.state === "1" &&
          this.form.wxAppId === res?.data?.wxAppId &&
          this.isQuery
        ) {
          this.isQuery = false;
          this.saveNext();
          await this.uploadCode(true);
          this.$message.success("授权成功");
          return;
        }
      } catch (error) {}
      if (this.isQuery) {
        this.clerTimer();
        this.timer = setTimeout(() => {
          this.tenantWxAppGetDetail();
        }, 1000);
      }
    },
    async submitNext(add = false, han = true) {
      if (han) {
        if (add) {
          this.active = this.active + 1;
        } else {
          this.active = this.active - 1;
        }
      }
      if (this.active === 1) {
        this.isQuery = true;
        const wxUrl = await getAuthorizationImageUrl(this.wxAppType);
        this.wxUrl = wxUrl;
        await this.setWxAppSetStep({ state: "0" });
        this.tenantWxAppGetDetail();
      } else if (this.active === 2) {
        this.isQuery = false;
        await this.setWxAppSetStep();
        this.uploadCode();
      } else {
        this.isQuery = false;
        await this.setWxAppSetStep();
      }
    },
    async uploadCode(up = false) {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.1)",
        customClass: "topLoading",
      });
      try {
        if ([-1, 1, 3].includes(this.auditStatus) || up) {
          try {
            await setPrivacySetting({
              appId: this.form.wxAppId,
              contactEmail: "refire1987@163.com",
              noticeMethod: "隼云科技收集信息",
              setting_list: [
                {
                  privacy_key: "UserInfo",
                  privacy_text: "用来登记会员信息",
                },
                {
                  privacy_key: "Location",
                  privacy_text: "用来展示你的位置信息",
                },
                {
                  privacy_key: "Address",
                  privacy_text: "用来展示你的地址",
                },
                {
                  privacy_key: "PhoneNumber",
                  privacy_text: "用来查看信息",
                },
                {
                  privacy_key: "Album",
                  privacy_text: "选中的照片或视频信息",
                },
                {
                  privacy_key: "AlbumWriteOnly",
                  privacy_text: "相册（仅写入）权限",
                },
                {
                  privacy_key: "ChooseLocation",
                  privacy_text: "选择的位置信息",
                },
              ],
            });
          } catch (error) {}
          let ext_json = {
            extEnable: true,
            extAppid: this.form.wxAppId,
            ext: {
              requiredPrivateInfos: ["getLocation", "chooseAddress"],
            },
          };
          const uploadInfo = await uploadCode({
            ext_json: JSON.stringify(ext_json),
            user_version: "V1.0",
            user_desc: "上传代码",
            appId: this.form.wxAppId,
          });
          if (uploadInfo.errcode >= 40000 || uploadInfo.errcode < 0) {
            return this.$message.error("代码上传失败");
          } else {
            const res = await submitCodeAudit({
              appId: this.form.wxAppId,
            });
            await this.getStatue();
            this.$message.success("提交成功");
          }
        } else {
          await this.getStatue();
        }
        const res = await TemplatedRaftList();
        const allList = res.draft_list?.sort?.(
          (a, b) => b.draft_id - a.draft_id
        );
        this.versionInfo = allList?.[0] || {};
      } catch (error) {}
      this.loading.close();
    },
    async getDetails() {
      try {
        const res = await wxAppGetDetail({ wxAppType: this.wxAppType });
        this.active = res.data?.wxAppStep || 0;
        this.load = true;
        if (res.data == null) return;
        this.form = res.data;
        this.form.wxAppType = this.wxAppType;
        if (res.data != null) {
          this.fileList = [];
          if (res.data.wxAppAuthImgUrl != null) {
            this.fileList.push({
              url: res.data.wxAppAuthImgUrl,
            });
          }
        }
      } catch (error) {}
      await this.submitNext(false, false);
    },
    async getStatue() {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.1)",
        customClass: "topLoading",
      });
      const res = await queryAuditStatus({
        appId: this.form.wxAppId,
      });
      this.auditStatus = res.status || res.status === 0 ? res.status : -1;
      this.loading.close();
    },
    async saveNext() {
      if (this.active == 0) {
        this.$refs.refForm.validate(async (valid) => {
          if (valid) {
            if (
              this.form.wxAppAuthImgUrl == "" ||
              this.form.wxAppAuthImgUrl == null
            ) {
              return this.$message.warning("请上传微信认证截图");
            }
            const res = await wxAppSave(this.form);
            this.$message.success(res.msg);
            this.submitNext(true);
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        this.submitNext(true);
      }
    },
    // 设置步骤 & 状态
    async setWxAppSetStep(state = {}) {
      await wxAppSetStep({
        wxAppType: this.wxAppType,
        wxAppStep: this.active,
        ...state,
      });
    },
    // 上一步
    async lastStep() {
      this.submitNext();
    },
    getFileItems(file) {
      this.form.wxAppAuthImgUrl = file.accessUrl;
    },
    setTimestampToTime(time) {
      return timestampToTime(time);
    },
    // 打开弹窗
    async openDialogFn() {
      //参数
      let query = {
        appId: this.form.wxAppId,
        path: "/pages/tabbar/home/index",
      };
      let res = await TrialQRCode(query);
      if (res) {
        this.QRCodeImg = res;
        this.title = "小程序体验码";
        if (this.QRCodeImg != "") {
          this.openDialog = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background: #e9e9e9;
  font-size: 13px;

  .fontColor {
    color: #949494;
  }

  .card1 {
    width: 600px;
    margin: 30px auto;
  }

  .card2 {
    //width: 400px;
    margin: 30px auto;
    padding: 10px;

    .qrCodeBox {
      padding: 10px;

      .img {
        width: 260px;
        height: 260px;
      }
    }

    .companyMsg {
      width: 100%;

      .img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }
    }
  }

  .btnBox {
    width: 100%;
  }
}
</style>


const req = require.context('../../assets/icons/svg', false, /\.svg$/)
const menuIcons = require.context('../../assets/icons/menu', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys()

const re = /\.\/(.*)\.svg/

export const icons = requireAll(req).map(i => {
  return i.match(re)[1]
})

export const MenuIcons = requireAll(menuIcons).map(i => {
  return i.match(re)[1]
})

// export default icons

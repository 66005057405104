var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.load
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("cardTitleCom", {
            staticClass: "bg-Color marT10",
            attrs: { cardTitle: "小程序设置" },
            scopedSlots: _vm._u(
              [
                {
                  key: "cardContent",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "padd15" },
                        [
                          _c(
                            "el-steps",
                            {
                              attrs: {
                                active: _vm.active,
                                simple: "",
                                "finish-status": "success",
                              },
                            },
                            [
                              _c("el-step", {
                                attrs: { title: "小程序申请及绑定" },
                              }),
                              _c("el-step", { attrs: { title: "扫码授权" } }),
                              _c("el-step", {
                                attrs: { title: "待微信审核通过自动上架" },
                              }),
                            ],
                            1
                          ),
                          _vm.active == 0
                            ? _c(
                                "div",
                                { staticClass: "card1" },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "refForm",
                                      attrs: {
                                        model: _vm.form,
                                        rules: _vm.rules,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "小程序AppId",
                                            prop: "wxAppId",
                                            "label-width": "220px",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.form.wxAppId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "wxAppId",
                                                  $$v
                                                )
                                              },
                                              expression: "form.wxAppId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "小程序原始ID",
                                            prop: "wxOriginalId",
                                            "label-width": "220px",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.form.wxAppOriginalId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "wxAppOriginalId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.wxAppOriginalId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "小程序密钥（AppSecret）",
                                            "label-width": "220px",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.form.wxAppSecret,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "wxAppSecret",
                                                  $$v
                                                )
                                              },
                                              expression: "form.wxAppSecret",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "商家联系方式",
                                            prop: "contactPhone",
                                            "label-width": "220px",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "input-with-select",
                                            attrs: {
                                              placeholder: "请输入手机号",
                                            },
                                            model: {
                                              value: _vm.form.wxAppTel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "wxAppTel",
                                                  $$v
                                                )
                                              },
                                              expression: "form.wxAppTel",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "小程序标题",
                                            "label-width": "220px",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.form.wxAppTitle,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "wxAppTitle",
                                                  $$v
                                                )
                                              },
                                              expression: "form.wxAppTitle",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "小程序标签",
                                            "label-width": "220px",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.form.wxAppTag,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "wxAppTag",
                                                  $$v
                                                )
                                              },
                                              expression: "form.wxAppTag",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { required: "" } },
                                        [
                                          _c("accessory-upload", {
                                            attrs: {
                                              listType: "picture-card",
                                              limit: 1,
                                              fileList: _vm.fileList,
                                              title: "上传小程序已微信认证截图",
                                            },
                                            on: {
                                              getFileItems: _vm.getFileItems,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.active == 1
                        ? _c("div", { staticClass: "el-card card2" }, [
                            _vm.wxUrl != ""
                              ? _c("iframe", {
                                  attrs: {
                                    width: "100%",
                                    height: "700px",
                                    scrolling: "yes",
                                    id: "myframe",
                                    src: _vm.wxUrl,
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              750449610
            ),
          }),
          _vm.active == 2
            ? _c(
                "div",
                {},
                [
                  _c("div", { staticClass: "el-card padd15" }, [
                    _c("div", { staticClass: "x-f" }, [
                      _c("i", { staticClass: "el-icon-success" }),
                      _c("span", { staticClass: "marL10" }, [
                        _vm._v(
                          "您已授权隼云科技自动为您的微信小程序“" +
                            _vm._s(_vm.versionInfo.source_miniprogram) +
                            "”发布新版"
                        ),
                      ]),
                    ]),
                    _vm._m(0),
                  ]),
                  _c("cardTitleCom", {
                    staticClass: "bg-Color marT10",
                    attrs: { cardTitle: "版本审核" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cardContent",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "padd15 x-bc fontColor" },
                                [
                                  _c("div", { staticClass: "x-f" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "y-c",
                                        staticStyle: {
                                          color: "#333333",
                                          width: "120px",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "fontS16" }, [
                                          _vm._v(" v"),
                                          _c(
                                            "span",
                                            { staticClass: "fontS20" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.versionInfo.user_version
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "fS14MR10 fontColor marT10",
                                          },
                                          [_vm._v("版本号")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "padd15 y-start COL",
                                        staticStyle: {
                                          "border-left": "1px solid #ababab",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "x-f" }, [
                                          _c("div", [
                                            _vm._v(" 提审者："),
                                            _c(
                                              "span",
                                              { staticClass: "fontColor2F" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.versionInfo.developer
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "marL100" },
                                            [
                                              _vm._v(" 发布时间："),
                                              _c(
                                                "span",
                                                { staticClass: "fontColor2F" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.setTimestampToTime(
                                                        _vm.versionInfo
                                                          .create_time || ""
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "x-f marL100" },
                                            [
                                              _vm._v(" 体验二维码: "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: _vm.openDialogFn,
                                                  },
                                                },
                                                [_vm._v("点击查看")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("div", [
                                          _c("div", { staticClass: "x-f" }, [
                                            _vm._v(" 项目备注: "),
                                            _c(
                                              "span",
                                              { staticClass: "fontColor2F" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.versionInfo.user_desc
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm.auditStatus === 0
                                      ? _c("span", [_vm._v("审核成功")])
                                      : _vm._e(),
                                    _vm.auditStatus === 1
                                      ? _c("span", [_vm._v("审核被拒绝")])
                                      : _vm._e(),
                                    _vm.auditStatus === 2
                                      ? _c(
                                          "span",
                                          [
                                            _vm._v("审核中 "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getStatue()
                                                  },
                                                },
                                              },
                                              [_vm._v("刷新状态")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.auditStatus === 3
                                      ? _c("span", [_vm._v("已撤回")])
                                      : _vm._e(),
                                    _vm.auditStatus === 4
                                      ? _c("span", [_vm._v("审核延后")])
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3489614666
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "padd15 x-c fontColor bg-Color" },
            [
              _vm.active >= 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.lastStep()
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  )
                : _vm._e(),
              _vm.active <= 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveNext()
                        },
                      },
                    },
                    [_vm._v(" 下一步 ")]
                  )
                : _vm._e(),
              _vm.active == 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.uploadCode(true)
                        },
                      },
                    },
                    [_vm._v(" 提交审核 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.openDialog,
                width: "30%",
                title: _vm.title,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.openDialog = $event
                },
              },
            },
            [
              _c("img", {
                attrs: { width: "100%", src: _vm.QRCodeImg, alt: "" },
              }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marT10" }, [
      _c("span", { staticClass: "marL20 fontColor fS14MR10" }, [
        _vm._v("若您要取消授权请您去微信小程序后台取消第三方授权"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }